import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavSpan,
} from "../../Navbar/NavbarElements";
import Icon from "../../../Assets/images/icons8-user-48.png"
import Image from "../../../Assets/images/megalogotheme.png"
import { FaBars } from "react-icons/fa"
import { IoLocationSharp } from "react-icons/io5";
import { HiMiniIdentification } from "react-icons/hi2";
import './Dashboard.css';
import { MdAlternateEmail } from "react-icons/md";
import { FaPhoneAlt } from 'react-icons/fa';

const Dashboard = () => {
  const usercap = localStorage.getItem("tokencap")
  const response = localStorage.getItem("datacap")
    ? (localStorage.getItem("datacap")) : [];
  const details = JSON.parse(response);
  const handleLogOut = () => {
    localStorage.removeItem("tokencap")
    window.location.reload();
  }
  return (
    <div className="dashboard-page-content ">
      <>

        <IconContext.Provider value={{ color: "white" }}>
          <Nav>
            <NavbarContainer>
              <NavLogo to="/">
                <img src={Image} height={40} width={40} alt=""></img>
                <NavSpan>Mega</NavSpan>lith
              </NavLogo>
              <MobileIcon>
                <FaBars />
              </MobileIcon>
              <NavMenu>
                {(usercap) ? (
                  <NavItem>
                    <NavLinks
                      onClick={handleLogOut}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      activeClass="active"
                    >
                      Logout
                    </NavLinks>
                  </NavItem>)
                  :
                  (<div></div>)
                }
              </NavMenu>
              <NavBtn>
                {(usercap) ? (<NavBtnLink to={`/dashboard/${JSON.parse(localStorage.getItem("datacap"))._id}`}>
                  <img src={Icon} height={40} width={40} alt=""></img>
                </NavBtnLink>) : ((<NavBtnLink to={"/signin"}>
                  <img src={Icon} height={40} width={40} alt=""></img>
                </NavBtnLink>))
                }

              </NavBtn>
            </NavbarContainer>
          </Nav>
        </IconContext.Provider>

      </>
      <div className=" ">
        <div className="p-4" style={{ height: "100vh" }}>
          <div className="">
            <div className=" user_card w-full md:w-1/2 ">
              <div className="  bg-[#B31312] p-4 text-center text-white">
                <div className="  mb-4">
                  <img
                    src="https://img.icons8.com/bubbles/100/000000/user.png"
                    className="rounded-full w-16 h-16 mx-auto"
                    alt="User-Profile"
                  />
                </div>
                <h6 className="text-3xl font-semibold">{details.Name}</h6>
                <i className="feather icon-edit mt-4 text-2xl"></i>
              </div>
              <div className=" p-4">
                <h6 className=" information  text-3xl mb-5 pb-2 border-b font-semibold text-white">
                  Information
                </h6>
                <div className="information_display grid grid-cols-3 gap-4">
                  <div className="w-1/2 py-5 ">
                    <p className="information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                      <MdAlternateEmail className='mr-2 ' /> Email
                    </p>
                    <h6 className="text-white flex flex-row items-center text-xl details">{details.Email}</h6>
                  </div>
                  <div className="w-1/2 py-5">
                    <p className=" information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                      <FaPhoneAlt className='mr-2' /> Phone
                    </p>
                    <h6 className="text-white text-xl details">{details.MobNumber}</h6>
                  </div>
                  <div className="w-1/2 py-5">
                    <p className="information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                      <HiMiniIdentification className='mr-2 ' /> Megalith-Id
                    </p>
                    <h6 className="text-white text-xl details">{details.MegalithID}</h6>
                  </div>
                  <div className="w-1/2 py-5">
                    <p className="information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                      <IoLocationSharp className='mr-2 ' /> State
                    </p>
                    <h6 className="text-white text-xl details">{details.State}</h6>
                  </div>
                  <div className="w-1/2 py-5">
                    <p className=" information_text flex flex-row items-center text-3xl mb-2 font-semibold text-white">
                      <IoLocationSharp className='mr-2 ' /> City
                    </p>
                    <h6 className="text-white text-xl details">{details.City}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Dashboard