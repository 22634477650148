
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {
  Route,
  Routes
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

  {/* <BrowserRouter>
  <Routes>
        <Route path="/" element={ <App /> }>
        </Route>
      </Routes>
  </BrowserRouter> */}
  <App/>

  </React.StrictMode>
);
