// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container-verifiedd{
    margin: 2px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#container-verifiedd *{
    font-family: 'Open Sans', sans-serif;
}

#container-verifiedd h1{
    margin: 10px;
}

#signinn{
    margin-top: 30px;
    padding: 10px;
    border-radius: 10px;
    border: 1px;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.362);
}
#signinn:hover{
    color: white;
    background-color: black;
}

@media screen and (max-width: 550px) {
    #container-verifiedd{
        width: 300px;
    }
    #container-verifiedd h1{
        font-size:35px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Login/EmailVerify/EmailVerify.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,iBAAiB;IACjB,4CAA4C;AAChD;AACA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,cAAc;IAClB;AACJ","sourcesContent":["#container-verifiedd{\n    margin: 2px;\n    border-radius: 7px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n#container-verifiedd *{\n    font-family: 'Open Sans', sans-serif;\n}\n\n#container-verifiedd h1{\n    margin: 10px;\n}\n\n#signinn{\n    margin-top: 30px;\n    padding: 10px;\n    border-radius: 10px;\n    border: 1px;\n    text-transform: uppercase;\n    font-weight: bold;\n    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.362);\n}\n#signinn:hover{\n    color: white;\n    background-color: black;\n}\n\n@media screen and (max-width: 550px) {\n    #container-verifiedd{\n        width: 300px;\n    }\n    #container-verifiedd h1{\n        font-size:35px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
