import React,{useEffect} from 'react'
import axios from 'axios'
import { useState } from 'react'
import "./ForgotPassword.css";
import toast, { Toaster } from 'react-hot-toast';
const ForgotPassword = () => {
    const [email,setEmail]=useState('');
    const [msg,setMsg]=useState('');
    const [error,setError]=useState('');

    const handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            const url=`${process.env.REACT_APP_BASE_URL}/password-reset`;
            const {data:res}=await axios.post(url,{Email:email});
            setMsg(res.message);
            setError(res.error);
        } catch (error) {
            setError(error.response.data.error);
        }
    };
    const notify=()=>{
      if(msg){
          toast.success(msg);
      }
      if(error){
        toast.error(error);
      }
    };
    useEffect(()=>{
      notify();
      },[msg,error]
    );
    
  return (
    <div id="container-submission body">
      <Toaster position="top-center" reverseOrder={false}/>
      <form id="form-submission" onSubmit={handleSubmit}>
        <h1>Enter your Email</h1>
        <input type="email" placeholder='Email' name='Email' id="email-inputt" onChange={(e)=>setEmail(e.target.value)} value={email} required/> 
        <button onClick={notify} type="submit"  className='btn'>Submit</button>
      </form>
    </div>
  )
}

export default ForgotPassword
