// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsibilities-div {
  background: linear-gradient(253deg, #78C1F3, #9BE8D8, #E2F6CA);
  background-size: 300% 300%;
  animation: Background 5s ease infinite;
}

@keyframes Background {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.full-screen {
  /* position: fixed; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(https://i.imgur.com/wCG2csZ.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  /* works with row or column */

  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}`, "",{"version":3,"sources":["webpack://./src/Components/Background/Background1.css"],"names":[],"mappings":"AAAA;EACE,8DAA8D;EAC9D,0BAA0B;EAG1B,sCAAsC;AACxC;;AA0BA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,qBAAqB;EACrB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,gDAAgD;EAChD,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,YAAY;EAEZ,aAAa;EAEb,6BAA6B;;EAE7B,sBAAsB;EAEtB,mBAAmB;EAEnB,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":[".responsibilities-div {\n  background: linear-gradient(253deg, #78C1F3, #9BE8D8, #E2F6CA);\n  background-size: 300% 300%;\n  -webkit-animation: Background 5s ease infinite;\n  -moz-animation: Background 5s ease infinite;\n  animation: Background 5s ease infinite;\n}\n\n@-webkit-keyframes Background {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n\n@-moz-keyframes Background {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n\n@keyframes Background {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n\n.full-screen {\n  /* position: fixed; */\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background: url(https://i.imgur.com/wCG2csZ.png);\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  display: -webkit-flex;\n  display: flex;\n  -webkit-flex-direction: column;\n  /* works with row or column */\n\n  flex-direction: column;\n  -webkit-align-items: center;\n  align-items: center;\n  -webkit-justify-content: center;\n  justify-content: center;\n  /* text-align: center; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
