import Home from './Components/Home';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams
} from "react-router-dom";
import Login from "./Components/Login/Login";
import ForgotPassword from "./Components/Login/ForgotPassword/ForgotPassword";
import Dashboard from "./Components/Login/Dashboard/Dashboard";
import PasswordReset from "./Components/Login/PasswordReset/PasswordReset";
import EmailVerify from "./Components/Login/EmailVerify/EmailVerify";

const App=()=> {
  const usercap=localStorage.getItem("tokencap");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/signin" element={<Login/> } exact />
        <Route path='/forgot-password' element={<ForgotPassword/>} exact/>
        <Route path="/" element={<Navigate replace to="/signin" />} />
        <Route path="/password-reset/:id/:token" element={<PasswordReset/>}/>
        <Route path="/users/:id/verify/:token" element={<EmailVerify/>}/>
        {usercap ? <Route path="/dashboard/:id" element={<Dashboard/>} exact/> : <Route path="/dashboard/:id" element={<Navigate replace to="/signin" />} exact/>}
      </Routes>
    </BrowserRouter>
    );
  }
  
  export default App;
  
 